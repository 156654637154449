import { template as template_a19cc5f9e6fb4d3db99770d894b2ccae } from "@ember/template-compiler";
const FKText = template_a19cc5f9e6fb4d3db99770d894b2ccae(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
