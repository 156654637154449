import { template as template_32a144fe007949a984dd25745df8a51f } from "@ember/template-compiler";
const FKLabel = template_32a144fe007949a984dd25745df8a51f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
