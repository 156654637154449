import { template as template_147f6e389260421d9131cec41a4a6ad2 } from "@ember/template-compiler";
const WelcomeHeader = template_147f6e389260421d9131cec41a4a6ad2(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
